import React from 'react';
import {Button, Card, Col, Result, Row} from "antd";
import {Link} from "gatsby";

export default function NotFoundPage() {
  return (
    <Row justify="center" align="middle" style={{height:"100vh"}}>
      <Col span={16}>
        <Card>
          <Result
            status="404"
            title="404"
            subTitle="La page que vous checher n'existe pas"
            extra={<Button type="primary"><Link to="/">Accueil</Link></Button>}
          />
        </Card>
      </Col>
    </Row>
  );
}